<template>
  <v-card>
    <v-card-title>
      Доступные токены компании "{{ company_name }}"
    </v-card-title>
    <v-card-text v-if="loading">
      <Loader />
    </v-card-text>
    <v-card-text>
      <RefNavigator 
        :items="tokens"
        :headers="headers"
        :hasAdditionBtn="true"
        :hasEditBtns="true"
        :hasShowRemovedBtn="true"
        :hasSearchTextField="true"
        @click:add="dialog=true"
        @delete="onDelete"
        @restore="onRestore"
      />
      <v-dialog
        v-model="dialog"
        max-width="min-content"
      >
        <v-card min-width="300px">
          <v-toolbar color="green" class="ma-0" dark flat>
            <v-toolbar-title>
            <span>Создание токена</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <TextField v-model="new_token_comment" label="Комментарий к новому токену" />
          </v-card-text>

          <v-card-actions>
              <v-btn text color="success" dark @click="saveToken">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>

import RefNavigator from '../components/RefNavigator.vue'
import Loader from '../components/ui/Loader.vue'
import TextField from '../components/ui/TextField.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'Tokens',
  components: {
    RefNavigator, Loader, TextField
  },
  data: () => ({
    loading: true,
    company_name: '',
    tokens: [],
    dialog: false,
    new_token_comment: '',
  }),
  computed: {
    ...mapGetters(['user', ]),
    headers() {
      return [
        {
          text: 'Значение',
          align: 'start',
          value: 'value'
        },
        {
          text: 'Комментарий',
          value: 'comment'
        }
      ]
    }
  },
  async mounted() {
    this.tokens = await this.$store.dispatch('load_tokens', this.user.company_id)
    let response = await this.$store.dispatch('get_user_company')
    this.company_name = response.name
    this.loading = false
  },
  methods: {
    async saveToken() {
      let response = await this.$store.dispatch('create_token', {
        company_id: this.user.company_id, 
        token: { comment : this.new_token_comment }
      })
      this.tokens.push(response)
      this.new_token_comment = ''
      this.dialog = false
    },
    update_tokens(token) {
      for (let obj in this.tokens) {
        if (obj.id == token.id) {
          obj = token
        }
      }
    },
    onDelete(item) {
      this.$store.dispatch('update_token', item)
    },
    onRestore(item) {
      this.$store.dispatch('update_token', item)
    },
  }
}
</script>